import rest from '@/core/restAPI';

export const getWidget = async ({ commit }, { widgetId }) => {
  const settings = await rest.get(`public/widget/${widgetId}`).json();
  commit('setSettings', settings);
};

export const getOpenClosedDates = async (
  { commit, state },
  { widgetId, booking, startDate, endDate }
) => {
  commit('setDates', []);
  const url = `public/widget/${widgetId}/experience/${
    booking.bookingStationTimes[0].experience.id
  }/open?start_date=${encodeURIComponent(
    startDate
  )}&end_date=${encodeURIComponent(endDate)}`;
  const dates = await rest.get(url).json();
  commit('setDates', dates);
};

export const getAvailableTimes = async (
  { commit, state },
  { widgetId, booking, date, numberOfPlayers }
) => {
  commit('setSettings', { ...state.settings, startTimes: null });
  const url = `public/widget/${widgetId}/experience/${
    booking.bookingStationTimes[0].experience.id
  }?date=${encodeURIComponent(date)}&players=${numberOfPlayers}`;
  const { startTimes, stationAmounts } = await rest.get(url).json();

  commit('setSettings', { ...state.settings, startTimes, stationAmounts });
};

export const getAvailableTiers = async (
  { commit, state },
  { widgetId, booking }
) => {
  commit('setSettings', { ...state.settings, tiers: null });
  const url = `public/widget/${widgetId}/experience/${
    booking.bookingStationTimes[0].experience.id
  }/available/${encodeURIComponent(booking.startTime)}?stations=${booking.bookingStationTimes.length}`;
  const tiers = await rest.get(url).json();
  commit('setSettings', { ...state.settings, tiers });
};

export const getAllTiers = async (
  { commit, state },
  { experience }
) => {
  commit('setSettings', { ...state.settings, tiers: null });
  const urls = [];
  for (let i = 0; i < experience.length; i++) {
    urls.push(`public/widget/experience/${experience[i]}/tiers`);
  }
  const requests = urls.map(url => rest.get(url).json());
  const tiers = await Promise.all(requests);
  commit('setSettings', { ...state.settings, tiers });
};

export const calculatePrice = async (
  { commit },
  { widgetId, booking, couponCode }
) => {
  let url = `public/widget/${widgetId}/experience/${
    booking.bookingStationTimes[0].experience.id
  }/available/${encodeURIComponent(booking.startTime)}/tier/${
    booking.bookingStationTimes[0].tier.id
  }?stations=${booking.bookingStationTimes.length}`;
  if (couponCode) url += `&couponCode=${couponCode}`;
  return rest.get(url).json();
};

export const makeBooking = async ({ commit }, payload) => {
  return rest.post(`public/booking`, { json: payload }).json();
};

export const startCheckout = async ({ commit }, { widgetId, experienceId, payload }) => {
  const { sessionId } = await rest.post(`public/widget/${widgetId}/experience/${experienceId}/start-checkout`, { json: payload }).json();
  return sessionId;
};

export const changeBooking = async ({ commit }, { widgetId, payload }) => {
  const { status } = await rest.post(`public/widget/${widgetId}/unlock`, { json: payload }).json();
  return status;
};
