export const buttonStyle = state => {
  if (state.settings.widget) {
    return {
      color: '#' + state.settings.widget.submitButtonTextColor,
      background: '#' + state.settings.widget.submitButtonBackgroundColor,
      borderColor: '#' + state.settings.widget.submitButtonBackgroundColor,
    };
  } else {
    return {
      color: '#F7F8F8',
      background: '#EC4E36',
      borderColor: '#EC4E36',
    };
  }
};

export const secondaryButtonStyle = state => {
  return {
    color: '#F7F8F8',
    background: '#EC4E36',
    border: '1px solid #EC4E36',
  };
};

export const dates = state => {
  return state.dates;
};
