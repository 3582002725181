<template>
  <form class="birthdate-picker" novalidate="novalidate" @submit.prevent>
    <UIInput v-model="inputs.day" class="input daysinput" maxlength="2" pattern="[0-9]*" :class="{'order-2': mdyFormatting}" type="text" name="" value="" :placeholder="$t('Day')" @keydown="validateDigit" @blur="onBlur" @focus="setFocusTrue" required />
    <UISelect
      v-model="inputs.month"
      class="input monthsinput"
      :class="{'order-1': mdyFormatting}"
      :placeholder="$t('Month')"
      name
      required
      @blur="onBlur"
      @focus="setFocusTrue"
    >
      <option v-for="({ value, name }) in months" :key="name" :value="value">{{ name }}</option>
    </UISelect>
    <UIInput v-model="inputs.year" class="input yearsinput" type="text" name="" maxlength="4" pattern="[0-9]*" value="" :class="{'order-3': mdyFormatting}" :placeholder="$t('Year')" @keydown="validateDigit" @blur="onBlur" @focus="focus=true" required/>
  </form>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import Component from 'vue-class-component';

import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';

@Component({
  props: {
    value: {
      type: Date,
    },
    mdyFormatting: {
      type: Boolean,
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler: 'onInput',
    },
    value: {
      immediate: true,
      handler (val) {
        if (!val) return;
        const momentDate = moment(val);
        if (!momentDate.isValid()) return;
        const { years: year, months: month, date: day } = momentDate.toObject();
        this.inputs = {
          year,
          month,
          day,
        };
      },
    },
  },
  components: {
    UIInput,
    UISelect,
  },
})
export default class BirthdayPicker extends Vue {
  months = moment.months().map((name, i) => ({
    name,
    value: i,
  }))
  inputs = {
    day: null,
    month: null,
    year: null,
  }
  focus=false
  get momentDate () {
    return moment(this.inputs);
  }
  onInput () {
    this.$emit('input');
    const { valid, reason } = this.validate();

    if (valid) {
      this.$emit('change', this.momentDate.toDate());
    } else {
      this.$emit('invalid', { reason });
    }
  }
  onBlur () {
    this.focus = false;

    // Weirdly using this.nextTick(); wasn't working. so I hacked-in setTimeout
    setTimeout(() => {
      if (!this.focus) this.$emit('blur');
    }, 0);
  }
  setFocusTrue () {
    this.focus = true;
  }
  validate () {
    let reason;
    const setReason = (_reason) => {
      if (!reason) reason = _reason;
      return false;
    };
    const isComplete = Object.values(this.inputs).every(val => val || (Number(val) === 0 && val !== '')) || setReason('incomplete');
    const isYearYYYY = String(this.inputs.year).length === 4 || setReason('year_digits');
    const isYearValid = Number(this.inputs.year) > 1899 || setReason('invalid');
    const isDateValid = this.momentDate.isValid() || setReason('invalid');
    const isValid = isComplete && isYearYYYY && isYearValid && isDateValid;
    return { valid: isValid, reason };
  }
  validateDigit (evt) {
    return true;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.birthdate-picker {
  display: flex;
  & select {
    @apply --select;
    &:invalid {
      color: #707070;
    }
  }
  & input {
    @apply --text;
  }
  & > * {
    margin-right: var(--spacingXS) !important;
    width: 33%;
    flex: 1 1 auto;
  }
  & > *:last-child {
    margin-right: 0 !important;
  }
  & .order-1 {
    order: 1;
  }
  & .order-2 {
    order: 2;
  }
  & .order-3 {
    order: 3;
  }
}
</style>
