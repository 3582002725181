import rest from '@/core/restAPI';
import moment from 'moment-timezone';

export const getBooking = async ({ dispatch }, { customerId, bookingId }) => {
  const booking = await rest.get(`public/booking/${bookingId}`).json();
  dispatch('insertEntities', {
    name: 'Booking',
    payload: booking,
  });
  return booking;
};

export const deleteBooking = async ({ dispatch }, { customerId, bookingId }) => {
  dispatch('insertEntities', {
    name: 'Booking',
    payload: { id: bookingId, deletedAt: moment().format() },
  });
  const booking = await rest.delete(`public/booking/${bookingId}`);
  return booking;
};
