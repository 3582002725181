import { gql } from '@/core/shared/api';
import store from '@/core/store';
import AuthPlugin from '@/core/shared/api/plugins/AuthPlugin';
import TransactionTrackingPlugin from '@/core/shared/api/plugins/TransactionTrackingPlugin';
import CancelPlugin from '@/core/shared/api/plugins/CancelPlugin';
import LocalePlugin from '@/core/shared/api/plugins/LocalePlugin';
import ErrorHandlingPlugin from '@/core/shared/api/plugins/ErrorHandlingPlugin';

gql.use(AuthPlugin, { store });
gql.use(ErrorHandlingPlugin, { store });
gql.use(LocalePlugin, { locale: window.sbvrenv.BROWSER_LOCALE });
gql.use(CancelPlugin);
gql.use(TransactionTrackingPlugin);
