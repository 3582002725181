export const setWaiverBooking = (state, booking) => {
  state.booking = booking;
};

export const setWaiverLocation = (state, location) => {
  state.location = location;
};

export const setWaiverHost = (state, host) => {
  state.host = host;
};

export const setWaiverStationTimes = (state, stationTimes) => {
  state.stationTimes = stationTimes;
};

export const setWaiverContent = (state, waiverContent) => {
  state.waiverContent = waiverContent;
};

export const setWaiverSignedWaivers = (state, waivers) => {
  state.signedWaivers = waivers;
};

export const removeWaiverSignedWaiver = (state, waiverId) => {
  const i = state.signedWaivers.map(item => item.id).indexOf(waiverId);
  state.signedWaivers.splice(i, 1);

  state.booking.waiverNumSigned--;
};

export const setWaiverMyWaiver = (state, waiver) => {
  state.myWaiver = waiver;
};
