<template>
  <RouterView />
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class Panel extends Vue {

}
</script>

<style scoped>
@import 'core/shared/styles';
</style>
