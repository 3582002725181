export const state = {
  booking: {},
  location: {},
  host: {},
  stationTimes: [],
  waiverContent: {},
  signedWaivers: [],
  myWaiver: {
    email: '',
  },
};
