import localize from '@/core/shared/localization';
import localizeMoment from '@/core/shared/localization/moment';

export let i18n;

export function localizeApp (locale) {
  window.springboardVRBrowserLocale = locale;
  i18n = localize(window.sbvrenv.BROWSER_LOCALE, 'customer-panel');
  localizeMoment(locale);
}
