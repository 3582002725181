<template>
  <div class="manage-waivers">
    <div class="panel">
      <div v-if="!loading" class="loading-container">
        <div class="page">
          <h1 class="span-3">{{ $t('Manage waivers for this booking') }}</h1>
          <booking-info
            v-if="booking"
            :booking="booking"
            :location="location"
            :host="host"
            :stationTimes="stationTimes"
            :numSurprisedGuests="numSurprisedGuests"
            class="info"
          />
          <UISelect
            class="span-3"
            :title="$t('How many minors have joined?')"
            :value="booking.waiverNumMinors"
            @change="setNumMinors($event)"
          >
            <option
              v-for="n in getNumbersRange(0, booking.numPlayers)"
              :selected="booking.waiverNumSurprisedGuests === n"
              :key="n"
              :value="n">{{ $tc('1 person | {n} people', n) }}</option>
          </UISelect>
          <h2 class="span-3">{{ $t('Signed waivers') }} ({{ remainingWaivers + $t(' remaining') }}):</h2>
          <template v-if="signedWaivers && signedWaivers.length > 0">
            <div class="waivers-table span-3" v-for="waiver in signedWaivers" :key="waiver.id">
              <div class="column">{{ waiver.firstName + ' ' + waiver.lastName }}</div>
              <div class="column">{{ waiver.email }}</div>
              <div class="column">{{ elapsedTime(waiver.createdAt) }}</div>
              <div class="column" v-if="waiver.pdfPath">
                <a :href="waiver.pdfFileFullUrl" target="_blank">Download</a>
              </div>
              <div class="column"><UIIcon class="icon" name="x" v-on:click="removeWaiver(waiver.id)" /></div>
            </div>
          </template>
          <template v-else>
            <div class="waivers-table span-3">
              <div class="column">{{ $t('No waivers signed yet') }}</div>
            </div>
          </template>
          <div><UIButton @click="sign">{{ $t('Sign') }}</UIButton></div>
        </div>
      </div>
    </div>
    <confirm v-bind="confirm" @closeConfirm="closeConfirm" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { setWaiverSignedWaivers } from './store/mutations';
import Component from 'vue-class-component';
import BookingInfo from './components/BookingInfo';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import Confirm from '@/core/shared/components/Confirm';
import moment from 'moment';

@Component({
  props: {
    reference: {
      type: String,
      required: true,
    },
  },
  components: {
    UIButton,
    UIInput,
    UISelect,
    UIIcon,
    Confirm,
    BookingInfo,
  },
  computed: {
    ...mapGetters([
      'confirm',
    ]),
  },
  methods: {
    getNumbersRange: function (start, stop) {
      return new Array(stop - start + 1).fill(start).map((n, i) => start + i);
    },
  },
})
export default class ManageWaiversOperator extends Vue {
  loading = true;

  get buttons () {
    return [
      {
        name: this.$t('No, cancel'),
        type: 'cancel',
      },
      {
        name: this.$t('Yes, delete'),
        action: () => {
          this.$emit('confirm');
        },
      },
    ];
  }

  get booking () {
    return this.$store.state.waivers.booking;
  }

  get location () {
    return this.$store.state.waivers.location;
  }

  get host () {
    return this.$store.state.waivers.host;
  }

  get stationTimes () {
    return this.$store.state.waivers.stationTimes;
  }

  get signedWaivers () {
    return this.$store.state.waivers.signedWaivers;
  }

  set signedWaivers (waivers) {
    setWaiverSignedWaivers(this.$store.state, waivers);
  }

  get signWaiverUrl () {
    return 'https://' + window.sbvrenv.CUSTOMER_URL + '/sign-waiver/' + this.booking.waiverReferenceGuest;
  }

  get numSurprisedGuests () {
    return this.booking.waiverNumSurprisedGuests;
  }

  get remainingWaivers () {
    const remaining = this.booking.numPlayers - this.booking.waiverNumSigned - this.booking.waiverNumMinors;
    return remaining < 0 ? 0 : remaining;
  }

  elapsedTime (date) {
    return moment(date).fromNow();
  }

  getWaiverUrl (waiverId) {
    return this.$store.dispatch('downloadSignedWaiver', { waiverReferenceHost: this.reference, waiverId: waiverId });
  }

  removeWaiver (waiverId) {
    this.$store.commit('setConfirm', {
      message: this.$t('Are you sure you would like to remove this person from the guest list?'),
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: () => {
            this.signedWaivers = this.$store.dispatch('removeSignedWaiver', { waiverReferenceHost: this.reference, waiverId: waiverId }).then(() => {
              this.$store.commit('setFlash', {
                message: this.$t('Guest was removed'),
                type: 'success',
              });
            });
          },
        },
      ],
    });
  }

  setNumMinors (event) {
    this.$store.dispatch('setNumMinors', { waiverReferenceHost: this.reference, numMinors: event.target.value }).then(() => {
      this.$store.commit('setFlash', {
        message: this.$t('The number of minors has been updated'),
        type: 'success',
      });
    });
  }

  closeConfirm () {
    this.$store.commit('setConfirm', {
      message: '',
      buttons: [],
      show: false,
      onClose: null,
    });
  }

  sign () {
    window.open(this.signWaiverUrl, '_blank');
  }

  async created () {
    try {
      await this.$store.dispatch('getWaiverInfoHost', this.reference);
      this.loading = false;
    } finally {
      this.show = true;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.manage-waivers {
  @apply --contentContainer;

  max-width: 110rem;

  @media (--desktop) {
    padding: var(--spacingLg);
  }

  & .icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--colorSBRed) !important;
  }

  & .panel {
    @apply --modalDropShadow;

    background-color: var(--colorWhite);

    & h1 {
      @apply --f2;

      margin: 0;
    }

    & h2 {
      @apply --f5;

      margin-top: 0;
    }

    & .page {
      padding: var(--spacingSm);

      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: var(--spacingSm);

      @media (--tablet) {
        grid-template-columns: repeat(3, 1fr);
        padding: var(--spacingMd);
      }

      & .info {
        grid-column: span 3;
      }

      & .span-1 {
        grid-column: span 1;
      }

      & .span-2 {
        grid-column: span 2;
      }

      & .span-3 {
        grid-column: span 3;
      }
    }
  }

  & .waivers-table {
    padding: var(--spacingSm);
    background-color: var(--colorCasper);
    color: var(--colorSlate);
    display: grid;
    grid-template-columns: repeat(3, 1fr) 10% 5%;
    grid-gap: var(--spacingSm);

    & .column {
      @apply --f7;

    }
  }

  & .powered {
    display: block;
    margin-bottom: 15rem;
    text-align: center;

    & img {
      margin-top: var(--spacingMd);
      width: 20rem;
    }
  }

  & .locationname {
    @apply --f1;
    text-align: center;
  }

  & .loadinganimation {
    text-align: center;
    padding: var(--spacingMd);
  }

  & >>> .bottom-bar {
    z-index: var(--zNavMenu);
  }
}
</style>
