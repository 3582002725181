<template>
  <div class="station-selector"
    :class="darkMode ? 'dark' : 'light'">
    <div class="stations">
      <div class="f5 mb-xs">{{ $t('Select the number of stations') }}</div>
      <div class="grid">
        <div class="stationamount"
          v-for="stationAmount in stationAmountsBasedOnPlayers"
          :key="stationAmount.amount"
          @click="setNumberOfStations(stationAmount.amount)">
          <UIButton :unstyled="true"
            class="white-button"
            :class="{
              active:
                stationAmount.amount === booking.bookingStationTimes.length,
              'dark': darkMode
            }">
            {{ $tc('1 Station | {count} Stations', stationAmount.amount) }}
          </UIButton>
          <div v-if="stationAmount.discount && !isOrganizationEducation"
            class="discount f6">
            {{ stationAmount.discount.displayDiscount }}&nbsp;{{
              $tc('discount', 1)
            }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.preselectedTiers && this.preselectedTiers.length > 0">
      <div class="f5 mb-xs">
        <span>{{ $t('Length of time') }}</span>
        <span v-if="!isOrganizationEducation">{{ $t('(Price Per Station)') }}</span>
      </div>
      <div class="grid">
        <UIButton
        :unstyled="true"
        :key="this.preselectedTiers.id"
        class="white-button"
        :class="{
          active: true,
          'dark': darkMode
        }"
        >
          {{ this.preselectedTiers.length }} {{ $t('Min')
          }}<span v-if="!isOrganizationEducation">
            - {{ toLocationCurrency(this.preselectedTiers.price) }}</span></UIButton>
      </div>
      </div>
    <div v-else class="tiers">
      <div class="f5 mb-xs">
        <span>{{ $t('Length of time') }}</span>
        <span v-if="!isOrganizationEducation">{{ $t('(Price Per Station)') }}</span>
      </div>
      <div class="grid"
        v-if="tiers">
        <UIButton :unstyled="true"
          class="white-button"
          v-for="tier in findCorrectTier(experience.id)"
          :key="tier.id"
          @click="setTier(tier)"
          :class="{
            active: tier.id === booking.bookingStationTimes[0].tier.id,
            'dark': darkMode
          }">
          {{ tier.length }} {{ $t('Min')
          }}<span v-if="!isOrganizationEducation">
            - {{ toLocationCurrency(tier.price) }}</span>
        </UIButton>
      </div>
      <Loading v-else />
    </div>
    <AnalyticsPixel :url="analyticsPixelUrl"></AnalyticsPixel>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import deepmerge from 'deepmerge';
import moment from 'moment-timezone';
import Loading from '@/core/shared/components/Loading';
import LocationHelper from '@/core/shared/helpers/LocationHelper';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIButton from '@/core/shared/components/ui/UIButton';
import AnalyticsPixel from './AnalyticsPixel.vue';

@Component({
  props: {
    location: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    startTimeMinMax: {
      type: Object,
      required: true,
    },
    stationAmounts: {
      type: Array,
      required: true,
    },
    preselectedTiers: {
      type: Object,
      required: false,
    },
    tiers: {
      type: Array,
      required: false,
    },
    experience: {
      type: Object,
      required: true,
    },
    isOrganizationEducation: {
      type: Boolean,
      default: false,
    },
    analyticsPixelUrl: {
      type: String,
      required: false,
    },
    darkMode: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Loading,
    UISelect,
    UIButton,
    AnalyticsPixel,
  },
  methods: {
    getNumbersRange: function (start, stop) {
      return new Array(stop - start + 1).fill(start).map((n, i) => start + i);
    },
  },
})
export default class Part2 extends Vue {
  numberOfPlayers = this.startTimeMinMax.minPlayers;

  get stationAmountsBasedOnPlayers () {
    let stationAmounts = [];
    const minimumStationAmountBasedOnPlayerAmount = Math.floor(this.booking.guests.length / this.experience.maxPeoplePerStation);

    if (minimumStationAmountBasedOnPlayerAmount > 0) {
      const goUpTo = (this.booking.guests.length > this.startTimeMinMax.maxStations) ? this.startTimeMinMax.maxStations : this.booking.guests.length;

      for (
        let i = Math.ceil(this.booking.guests.length / this.experience.maxPeoplePerStation);
        i <= goUpTo;
        i++
      ) {
        stationAmounts.push(this.stationAmounts.find(stationAmount => stationAmount.amount === i));
      }
    } else {
      const belowMinimumAmount = (this.booking.guests.length > this.startTimeMinMax.maxStations) ? this.startTimeMinMax.maxStations : this.booking.guests.length;
      for (let i = 1; i <= belowMinimumAmount; i++) {
        stationAmounts.push(this.stationAmounts.find(stationAmount => stationAmount.amount === i));
      }
    }
    return stationAmounts;
  }

  created () {
    if (this.booking.numberOfPlayers) {
      this.numberOfPlayers = this.booking.numberOfPlayers;
      this.setNumberOfPlayers(this.numberOfPlayers);
    } else {
      this.setNumberOfPlayers(this.startTimeMinMax.minPlayers);
    }
    if (this.preselectedTiers && this.preselectedTiers.length > 0) {
      this.setTier(this.preselectedTiers);
    }
  }

  mounted () {
    if (this.$store.state.public.settings.widget.uaCode) {
      this.$gtm.trackView('Page2');
    }
  }

  async setNumberOfPlayers (players) {
    this.numberOfPlayers = players;
    this.booking.numberOfPlayers = players;

    const newBooking = deepmerge({}, this.booking);
    newBooking.guests = [];
    for (let i = 0; i < players; i++) {
      newBooking.guests[i] = deepmerge({}, this.booking.guests[0]);
    }
    this.$emit('update:booking', newBooking);
    await this.$nextTick();
    this.setNumberOfStations(this.stationAmountsBasedOnPlayers[0].amount);
  }

  setNumberOfStations (stations) {
    const newBooking = deepmerge({}, this.booking);
    newBooking.bookingStationTimes = [];
    for (let i = 0; i < stations; i++) {
      let mergeObject = {};
      newBooking.bookingStationTimes[i] = deepmerge(this.booking.bookingStationTimes[0], mergeObject);
      if (!this.preselectedTiers) {
        newBooking.bookingStationTimes[i].tier.id = null;
      }
    }
    this.$emit('update:booking', newBooking);
    if (!this.preselectedTiers) {
      this.$emit('getTiersForPlayers');
    }
  }

  setTier (tier) {
    if (this.preselectedTiers.id) {
      tier.id = this.preselectedTiers.id;
    }
    const newBooking = deepmerge({}, this.booking);
    newBooking.bookingStationTimes = newBooking.bookingStationTimes.map(bookingStationTime => {
      bookingStationTime.tier.id = tier.id;
      bookingStationTime.endTime = moment(this.booking.startTime).add(tier.length, 'minutes').format();
      bookingStationTime.amountDue = tier.price;
      bookingStationTime.amountPaid = 0;
      return bookingStationTime;
    });
    this.$emit('update:booking', newBooking);
    this.$emit('onTierUpdate');
  }

  findCorrectTier (experienceID) {
    for (const tierGroup of this.tiers) {
      for (const tier of tierGroup) {
        if (tier.experienceId === experienceID) {
          return tierGroup;
        }
      }
    }
    return [];
  }

  toLocationCurrency (number) {
    return LocationHelper.formatApiPriceIntoLocationCurrency(this.location, number);
  }
}
</script>

<style scoped lang="postcss">
@import 'core/shared/styles';

.white-button.ui-button {
  @apply --f4;
  border: 1px solid var(--colorGeyser);
  border-radius: 5px;
  padding: var(--spacingSm) var(--spacingXS);
  width: 100%;
  background-color: #F7F8F8;
  color: #1F1E1E;

  @media (--tablet) {
    padding: var(--spacingSm) var(--spacingMd);
  }

  &.active {
    border-color: var(--colorSBRedFlat);
    color: var(--colorSBRedFlat);
  }
}

.white-button.ui-button.dark {
  @apply --f4;
  border: 1px solid var(--colorGeyser);
  border-color: #283B57;
  border-radius: 5px;
  padding: var(--spacingSm) var(--spacingXS);
  width: 100%;
  background-color: #1F1E1E;
  color: #F7F8F8;

  @media (--tablet) {
    padding: var(--spacingSm) var(--spacingMd);
  }

  &.active {
    border-color: #F7F8F8;
  }
}

.station-selector {
  display: grid;
  grid-gap: var(--spacingMd);

  & .discount {
    color: var(--colorInfoFlat);
    margin-top: var(--spacingXS);
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.station-selector.dark {
  display: grid;
  grid-gap: var(--spacingMd);
  color: #F7F8F8;

  & .discount {
    color: var(--colorInfoFlat);
    margin-top: var(--spacingXS);
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
