<template>
    <iframe :src="urlWithQueryString" class="analytics-pixel" v-if="url">
    </iframe>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    url: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  components: {
  },
})
export default class AnalyticsPixel extends Vue {
  get urlWithQueryString () {
    const qsParams = new URLSearchParams(this.$route.query);
    if (qsParams.toString() === '') {
      return this.url;
    }
    const glue = (this.url.indexOf('?') !== -1) ? '&' : '?';
    return `${this.url}${glue}${qsParams.toString()}`;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.analytics-pixel {
  width: 0px;
  height: 0px;
}
</style>
