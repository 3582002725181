import { render, staticRenderFns } from "./GroupSizeSelector.vue?vue&type=template&id=b859d5ee&scoped=true&"
import script from "./GroupSizeSelector.vue?vue&type=script&lang=js&"
export * from "./GroupSizeSelector.vue?vue&type=script&lang=js&"
import style0 from "./GroupSizeSelector.vue?vue&type=style&index=0&id=b859d5ee&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b859d5ee",
  null
  
)

export default component.exports