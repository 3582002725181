<template>
    <div class="ma3 ml4 mr4">
        <h2 class="t-heading-3 mb3 text-center mt4">Select the game</h2>
        <div class="game-list grid grid2">
          <article class="game" v-for="game in games" :key="game.uuid" @click="selectedGameId = game.uuid" :class="{
            active: game.uuid === selectedGameId
          }">
            <img :src="game.imageUrl" :alt="game.title"/>
            <div class="flex justify-between pa3 pl4 pr4">
              <h3 class="title f3">{{ game.title }}</h3>
              <p class="price f5">{{ `${game.price}ct/per minute` }}</p>
            </div>
          </article>
        </div>
        <h2 class="t-heading-3 mb3 text-center mt4">Select duration</h2>
        <div class="duration-list grid grid2">
          <UIButton
            v-for="duration in durations"
            :key="duration"
            :ghost="true"
            class="white-button"
            @click="selectedDuration=duration"
            :class="{
              active: duration === selectedDuration
            }"
          >
            {{ $tc('{count} Minutes', duration) }}
          </UIButton>
        </div>
        <div class="bottom-bar">
          <UIButton :disabled="!selectedDuration || !selectedGameId || loading" @click="checkout">Play</UIButton>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Loading from '@/core/shared/components/Loading';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  props: {
    stationId: {
      type: String,
      required: true,
    },
  },
  components: {
    Loading,
    UIButton,
  },
})
export default class KioskBooking extends Vue {
  loading = false;
  durations = [10];
  selectedGameId = null;
  selectedDuration = null;

  async created () {
    await this.$store.dispatch('getAvailableGames', { stationId: this.stationId });
  }

  get games () {
    return this.$store.state.kiosk.games.reverse();
  }

  async checkout () {
    this.loading = true;
    const booking = await this.$store.dispatch('makeReservation', {
      stationId: this.stationId,
      gameId: this.selectedGameId,
      duration: this.selectedDuration,
    });

    if (booking) {
      this.$store.commit('setFlash', {
        type: 'success',
        message: this.$t('Launching a game might take while, Please wait for the it'),
      });
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.text-center {
  text-align: center;
}

.game-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* gap: 20px; */
}

.game {
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 #0000,0 0 #0000,0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);
  position: relative;
  border: 1px solid transparent;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(40, 59, 87, 0.2);
    opacity: 0;
    -webkit-transition: opacity .15s ease-in;
    transition: all .15s ease-in;
  }

  &.active {
    /* transform: scale(1.05); */
    border: 1px solid #283B57;
  }

  &.active::after {
    opacity: 1
  }

  & .title {
    @apply --f3;
  }
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .ui-button {
    width: 100%;
    border-radius: 0;
  }
}
</style>
