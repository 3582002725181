import configureEnvironment from '@/core/shared/setup/configureEnvironment';
configureEnvironment();

if (window.sbvrenv.NODE_ENV === 'development') {
  window.sbvrenv.CHECKOUT_URL =
    'https://checkout-api.springboardvr.docker';
} else if (
  window.sbvrenv.NODE_ENV === 'staging' ||
  window.sbvrenv.NODE_ENV === 'review'
) {
  window.sbvrenv.CHECKOUT_URL =
  'https://staging-checkout-api.springboardvr.com';
} else if (window.sbvrenv.NODE_ENV === 'production') {
  window.sbvrenv.CHECKOUT_URL =
    'https://checkout-api.springboardvr.com';
}
