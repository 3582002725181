<script>
import Component, { mixins } from 'vue-class-component';
import WebPanelMixin from '@/core/shared/web-panel/WebPanelMixin';

@Component()
export default class App extends mixins(WebPanelMixin) {
  usesAuth = false;
};
</script>

<style>
@font-face {
  font-family: "OpenSansLight";
  src: url(/fonts/opensans-light.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansRegular";
  src: url(/fonts/opensans-regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansBold";
  src: url(/fonts/opensans-bold.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TekoLight";
  src: url(/fonts/teko-light.ttf);
  font-weight: normal;
  font-style: normal;
}
</style>
