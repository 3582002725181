import Vue from 'vue';
import Router from 'vue-router';
import PublicBooking from '@/public/booking/PublicBooking';
import KioskBooking from '@/public/kiosk/KioskBooking';

import Panel from '@/panel/Panel';
import ManageBooking from '@/panel/booking/manage-booking/ManageBooking';
import ManageWaiversOperator from '@/panel/waivers/ManageWaiversOperator';
import ManageWaivers from '@/panel/waivers/ManageWaivers';
import SignWaiver from '@/panel/waivers/SignWaiver';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'kiosk-booking',
      path: '/kiosk/:stationId',
      component: KioskBooking,
      props: true,
    },
    {
      name: 'public-booking',
      path: '/reserve/:widgetId',
      component: PublicBooking,
      props: true,
    },
    // This is here for deprication reasons
    {
      name: 'depricated-public-booking',
      path: '/book/:widgetId',
      component: PublicBooking,
      props: true,
    },
    {
      name: 'panel',
      path: '/:customerId',
      component: Panel,
      props: true,
      children: [
        {
          name: 'manage-booking',
          path: 'reservation/:bookingId',
          component: ManageBooking,
          props: true,
        },
      ],
    },
    {
      name: 'manage-waivers',
      path: '/manage-waivers/:reference',
      component: ManageWaivers,
      props: true,
    },
    {
      name: 'manage-waivers-operator',
      path: '/manage-waivers/operator/:reference',
      component: ManageWaiversOperator,
      props: true,
    },
    {
      name: 'sign-waiver',
      path: '/sign-waiver/:reference',
      component: SignWaiver,
      props: true,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
