<template>
  <div class="sign-waivers">
    <div v-if="!loading" class="panel">
      <div class="page">
        <h1 class="span-3">{{ $t('Sign your waiver') }}</h1>
        <booking-info
          v-if="stationTimes"
          :booking="booking"
          :location="location"
          :host="host"
          :stationTimes="stationTimes"
          class="info span-3"
        />

        <template v-if="search">
          <UIInput
            class="span-3"
            :title="$t('Please enter your email address to begin')"
            v-model="email"
          />
          <UIButton class="span-3" @click="getPreviouslySignedWaiver(email)">{{ $t('Continue') }}</UIButton>
        </template>
        <template v-else-if="sign">
          <h2>{{ $t('Waiver contents') }}</h2>
          <div class="waiver-content span-3">{{ waiverContent.content }}</div>
          <UIInput
            class="span-1"
            :title="$t('Email')"
            v-model="myWaiver.email"
            required
          />
          <UIInput
            class="span-1"
            :title="$t('First name')"
            v-model="myWaiver.firstName"
            required
          />
          <UIInput
            class="span-1"
            :title="$t('Last name')"
            v-model="myWaiver.lastName"
            required
          />
          <template v-if="waiverContent.askForMarketingConsent">
            <div class="agree span-3">
              <label class="checkbox-label">
                <input type="checkbox" name="consentMarketing" v-model="myWaiver.consentMarketing" />
                <div class="copy f5">{{ $t('I would like to receive e-mail updates from this arcade') }}</div>
              </label>
            </div>
          </template>
          <div class="agree span-3">
            <label class="checkbox-label">
              <input type="checkbox" name="consentWaiver" v-model="consentWaiver" />
              <div class="copy f5">{{ $t('I agree to the waiver contents') }}</div>
            </label>
          </div>
          <UIButton class="span-1" @click="signWaiver()">{{ $t('Sign waiver') }}</UIButton>
        </template>
        <template v-else-if="signed">
          <h2>{{ $t('You\'ve signed the waiver!') }}</h2>
          <div class="waiver-content span-3">{{ waiverContent.content }}</div>
        </template>
        <template v-else-if="previouslySigned">
          <h2 class="span-3">{{ $t('You\'ve previously signed this waiver. You\'re set to go and can close this page when ready.') }}</h2>
          <div class="waiver-content span-3">{{ waiverContent.content }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BookingInfo from './components/BookingInfo';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import UICheckbox from '@/core/shared/components/ui/UICheckbox';

@Component({
  props: {
    reference: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      email: '',
      consentWaiver: false,
    };
  },
  components: {
    UIButton,
    UIInput,
    UICheckbox,
    BookingInfo,
  },
})
export default class SignWaiver extends Vue {
  loading = true;
  search = false;
  sign = false;
  previouslySigned = false;
  signed = false;

  get booking () {
    return this.$store.state.waivers.booking;
  }

  get location () {
    return this.$store.state.waivers.location;
  }

  get host () {
    return this.$store.state.waivers.host;
  }

  get waiverContent () {
    return this.$store.state.waivers.waiverContent;
  }

  get stationTimes () {
    return this.$store.state.waivers.stationTimes;
  }

  get myWaiver () {
    return this.$store.state.waivers.myWaiver;
  }

  get valid () {
    return !!this.myWaiver.email && !!this.myWaiver.firstName && !!this.myWaiver.lastName;
  }

  async getPreviouslySignedWaiver (email) {
    await this.$store.dispatch('getPreviouslySignedWaiver', { waiverReferenceGuest: this.reference, email: email }).then(() => {
      this.search = false;
      if (this.myWaiver.id) {
        this.previouslySigned = true;
      } else {
        this.sign = true;
      }
    });
  }

  async signWaiver () {
    if (!this.valid) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: 'Please fill all required fields',
      });
    } else if (!this.consentWaiver) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: 'You cannot sign the waiver without agreeing with its contents',
      });
    } else {
      await this.$store.dispatch('signWaiver', { waiverReferenceGuest: this.reference, language: window.sbvrenv.BROWSER_LOCALE.substring(0, 2) }).then(() => {
        this.sign = false;
        this.signed = true;
        window.scrollTo(0, 0);
      });
    }
  }

  async created () {
    try {
      await this.$store.dispatch('getWaiverInfoGuest', { waiverReferenceGuest: this.reference, language: window.sbvrenv.BROWSER_LOCALE.substring(0, 2) }).then(() => {
        this.loading = false;
        this.search = true;
      });
    } finally {
      this.show = true;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.sign-waivers {
  @apply --contentContainer;

  max-width: 110rem;

  @media (--desktop) {
    padding: var(--spacingLg);
  }

  & .panel {
    @apply --modalDropShadow;

    background-color: var(--colorWhite);

    & h1 {
      @apply --f2;

      margin: 0;
    }

    & h2 {
      @apply --f5;

      margin-top: 0;
    }

    & .page {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: var(--spacingSm);
      grid-row-gap: 1em;
      padding: var(--spacingSm);

      @media (--tablet) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: var(--spacingMd);

        & .span-1 {
          grid-column: span 1;
        }

        & .span-3 {
          grid-column: span 3;
        }
      }

      & .waiver-content {
        @apply --text;

        white-space: break-spaces;
        padding: var(--spacingSm);
      }
    }
  }

  & .agree label {
    display: flex;
    margin: var(--spacingSm) 0;
    height: 1.3rem; /* Fix for Edge and Firefox */
    & .copy {
      position: relative;
      margin-left: var(--spacingXS);
      bottom: 0.5rem;
    }
  }
}

</style>
