<template>
  <div class="page-switcher">
    <div class="page" :class="{ active: page === 1, pointer: page > 1 }" @click="switchPage(1)">
      <div class="dot"></div>
      <div class="text">{{ $t('Step 1') }}</div>
    </div>
    <div class="line"></div>
    <div class="page" :class="{ active: page === 2, pointer: page > 2 }" @click="switchPage(2)">
      <div class="dot"></div>
      <div class="text">{{ $t('Step 2') }}</div>
    </div>
    <!--<div class="line"></div>
    <div class="page" :class="{ active: page === 3, pointer: page > 3 }" @click="switchPage(3)">
      <div class="dot"></div>
      <div class="text">{{ $t('Step 3') }}</div>
    </div>-->
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    page: {
      type: Number,
      required: true,
    },
  },
})
export default class PageSwitcher extends Vue {
  switchPage (page) {
    if (this.page > page) this.$emit('update:page', page);
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.page-switcher {
  display: flex;
  justify-content: center;
  margin: var(--spacingSm) 0;

  & .page {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .dot {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 0.2rem solid var(--colorGeyser);
      margin-bottom: var(--spacingSm);
      background-color: transparent;
      transition: border 0.3s ease, background-color 0.3s ease;
      will-change: border, background-color;
    }

    & .text {
      @apply --f8;
    }

    &.active {
      & .dot {
        border: 0 solid var(--colorGeyser);
        background-color: var(--colorGeyser);
      }
    }

    &.pointer {
      cursor: pointer;
    }
  }

  & .line {
    height: 0.2rem;
    width: 3rem;
    background-color: var(--colorGeyser);
    margin: 0.4rem var(--spacingXS);
  }
}
</style>
