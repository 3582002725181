<template>
  <div class="booking-info">
    <div>
      <div>{{ location.title }}</div>
      <div>{{ location.address }}</div>
      <div>{{ location.address2 }}</div>
      <div>{{ location.postalCode }} {{ location.city }}</div>
      <div>{{ location.phone }}</div>
      <div>{{ location.email }}</div>
    </div>
    <div>
      <div>{{ $t('Booked by') }} {{ host.firstName + ' ' + host.lastName }}</div>
      <div>{{ $t('Starts at') }} {{ startTime }}</div>
      <div>{{ booking.numPlayers }} {{ $t('People') }}</div>
      <div v-if="numSurprisedGuests > 0">{{ numSurprisedGuests }} {{ $t('Surprised guests') }}</div>
      <div>{{ stationTimes.length }} {{ $t('Stations') }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// import moment from 'moment';

@Component({
  props: {
    booking: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    stationTimes: {
      type: Array,
      required: true,
    },
    numSurprisedGuests: {
      type: Number,
    },
  },
  components: {
  },
})
export default class BookingInfo extends Vue {
  get startTime () {
    return new Date(this.stationTimes.reduce(function (prev, current) {
      return (prev.startTime > current.startTime) ? prev : current;
    }).startTime).toLocaleString(window.sbvrenv.BROWSER_LOCALE);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.booking-info {
  @apply --f7;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 1em;

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}

</style>
