<template>
  <div :class="{ 'dark-mode': darkMode, 'light-mode': !darkMode }">
         <h1
              class="
              section-title container-width">1. {{ ($t("Select Experience")).toUpperCase() }}</h1>
              <div class="experiences container-width">
                <div class="experience"
                  v-for="experience in visibleExperiences"
                  :key="experience.id"
                  :class="{ 'active': isExperienceSelected(experience) }">
                   <div @click="experienceAction(experience)">
                          <div class="experience-header flex justify-between mb-xs">
                           <span
                             class="title">{{ experience.title }}</span>
                             <span
                             class="title">{{ experience.type }}</span>
                          </div>
                             <div class="experience-container">
                              <div class="image">
                                 <img class="w-100"
                                 :src="experience.imageUrl"
                                :alt="experience.title" />
                              </div>
                              <div class="experience-bottom">
                                <span class="description f6">
                                  {{ experience.description }}
                                </span>
                                <div v-if="getGroupSizeIconCount(experience) <= 8" class="experience-bottom">
                               MAX PLAYERS:
                               <template v-for="index in getGroupSizeIconCount(experience)">
                                 <svg :key="index"
                                 class="member">
                                 <use xlink:href="#icon-person-alt"></use>
                               </svg>
                              </template>
                            </div>
                            <div v-else class="experience-bottom">
                              <template>
                                <p class="maxPlayers" >
                                  Max players:
                                  <svg
                                  class="member">
                                  <use xlink:href="#icon-person-alt"></use>
                                </svg>
                                up to {{ getGroupSizeIconCount(experience) }}
                              </p>
                            </template>
                          </div>
                       </div>
                           </div>
                          </div>
                          <div class="experience-tiers">
                          <div v-for="tier in findCorrectTier(experience.id)" :key="tier.id" class="experience-tiers-grid">
                            <div class="experience-tiers-row experience-tiers-price" v-if="!isOrganizationEducation">
                              {{ toLocationCurrency(tier.price, settings.location) }}
                            </div>
                            <div v-else class="experience-tiers-row experience-tiers-price" >
                              {{ $t('free') }}
                            </div>
                            <div class="experience-tiers-row experience-tiers-length">
                              {{ tier.length }} {{ $t('Min') }}
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="experience">
                        </div>
                      </div>
                      <div class="experience-arrow left"
                 :class="{ 'enabled': showLeftArrow, 'disabled': hideArrow }"
                 @click="previousBatch()"
                 >
                 <img src="../../assets/icons.svg#icon-chevron-left-gradient"
                   alt="*">
               </div>
               <div class="experience-arrow right"
                       :class="{ 'enabled': this.showRightArrow, 'disabled': hideArrow }"
                           @click="nextBatch()"
                          >
                        <img src="../../assets/icons.svg#icon-chevron-right-gradient"
                          alt="*">
                      </div>
                    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import LocationHelper from '@/core/shared/helpers/LocationHelper';

@Component({
  props: {
    experiences: {
      type: Array,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    numberOfPlayers: {
      type: Number,
      required: true,
      default: 0,
    },
    darkMode: {
      type: Boolean,
      required: false,
    },
    widget: {
      type: Object,
    },
    settings: {
      type: Object,
    },
    isOrganizationEducation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
})
export default class ExperienceSelector extends Vue {
  page = 0;
  timerEndTime = null;
  timer = null; // 15 minutes: 60 seconds * 15
  intervalId = null;

  sessionId = null;

  loading = true;
  priceCalculating = false;

  priceInfo = null;
  selectedGatewayId = null;
  date = null;
  time = null;

  transactionId = null;
  pendingRedirection = false;
  showLeftArrow = false;
  showRightArrow = false;
  hideArrow = false;
  experiencePage = 1;
  visibleExperiences = [];
  preselectedTier = {};

  get info () {
    return this.$store.state;
  }

  get valid () {
    if (this.page === 0) return false;
    if (this.page === 1) return !!this.booking.startTime && !!this.settings.startTimes && !!this.booking.bookingStationTimes[0].tier.id;
  }

  get tiers () {
    return this.settings.tiers;
  }

  get stationAmounts () {
    return this.settings.stationAmounts;
  }

  get location () {
    return this.settings.location;
  }

  getGroupSizeIconCount (experience) {
    return experience.maxPeoplePerStation;
  }

  getAllTiers () {
    const experienceIdArray = [];
    this.experiences.forEach(experience => {
      experienceIdArray.push(experience.id);
    });
    this.$store.dispatch('getAllTiers', { experience: experienceIdArray });
  }

  async created () {
    await this.getAllTiers();

    this.page = 1;

    this.booking.location.id = this.settings.location.id;
    this.booking.bookingStationTimes[0].experience.id = this.experiences[0].id;

    this.visibleExperiences = this.experiences.slice(0, 6);
    if (this.experiences.length <= 6) {
      this.hideArrow = true;
    }

    if (this.experiences && this.experiences.length > 6) {
      this.showRightArrow = true;
    }

    this.loading = false;
  }

  onPageUpdate (page) {
    this.page = page;
  }

  isExperienceSelected (experience) {
    return experience.id === this.booking.bookingStationTimes[0].experience.id;
  }

  onBookingUpdate (booking) {
    this.booking = booking;
  }
  getTiersForPlayers () {
    this.priceInfo = null;
    this.$store.dispatch('getAvailableTiers', { widgetId: this.widgetId, booking: this.booking });
  }

  resetTimer () {
    clearInterval(this.intervalId);
    this.timerEndTime = null;
    this.timer = null;
    this.page = 1;
  }

  displayExperiences () {
    const startIndex = this.experiencePage * 6 - 6;
    const endIndex = startIndex + 6;
    const items = this.experiences;

    const currentBatch = items.slice(startIndex, endIndex);

    this.visibleExperiences = currentBatch;
  }

  nextBatch () {
    const maxBatches = Math.ceil(this.experiences.length / 6);
    this.experiencePage++;
    this.showLeftArrow = true;
    if (this.experiencePage === maxBatches) {
      this.displayExperiences();
      this.showRightArrow = false;
    } else if (this.experiencePage < maxBatches) {
      this.displayExperiences();
    } else {
    }
  }

  previousBatch () {
    this.experiencePage--;
    this.showRightArrow = true;
    if (this.experiencePage === 1) {
      this.displayExperiences();
      this.showLeftArrow = false;
    } else if (this.experiencePage > 1) {
      this.displayExperiences();
    }
  }

  toLocationCurrency (number, location) {
    return LocationHelper.formatApiPriceIntoLocationCurrency(location, number);
  }

  findCorrectTier (experienceID) {
    const result = this.settings.tiers.find(tierGroup =>
      tierGroup.some(tier => tier.experienceId === experienceID)
    ) || [];
    return result;
  }

  experienceAction (experience) {
    this.booking.bookingStationTimes[0].experience.id = experience.id;
    this.$emit('onSelect', experience);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.light-mode {
.container-width {
  @apply --contentContainer;
  max-width: 120rem;
  padding: 0 var(--spacingM);
}

.experiences {
  display: grid;
  z-index: 5;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-top: var(--spacingMd);

  @media (--tablet) {
    gap: 80px;
  }

  @media (--desktop) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.experience {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & .experience-header {
    flex-direction: column;

    @media (--tablet) {
      flex-direction: row;
    }
  }

  .title {
    @apply --f5;
    font-size: 14px;
    color: #293E5D;

    @media (--tablet) {
      font-size: 18px;
    }
  }

  .member {
    fill: #879AB2;
    width: 16px;
    height: 18px;
    margin-right: 4px;
    margin-bottom: -5px;

    @media(--tablet) {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  .description {
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
  }

  .image {
    border: 1px solid #879AB2;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    display: flex;

    img {
      aspect-ratio: 280 / 180;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity .15s ease-in;
    }

    &:hover {
      border-color: var(--colorSBRedFlat);

      &:after {
        opacity: 0.5;
      }
    }
  }

  &.active .image {
    border-color: var(--colorSBRedFlat);

    &:after {
      opacity: 1;
    }
  }
}

.section-title {
  @apply --f2;
  font-size: 26px;
  z-index: 5;
  font-weight: 800;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: var(--spacingSm);

  @media (--tablet) {
    font-size: 35px;
    margin-bottom: var(--spacingMd);
  }
}

  .experience-arrow {
    position: absolute;
    pointer-events: none;
    transition: opacity 0.3s ease;
    cursor: pointer;
    filter: opacity(0.7);
    filter: grayscale(70%);

    &.left {
      left: 12em;
      bottom: 70%;
    }

    &.right {
      left: 165em;
      bottom: 70%;
    }

    &.enabled {
      pointer-events: auto;
      filter: opacity(0.6);

      &:hover {
        filter: opacity(1);
      }
    }
    &.disabled {
      display:none;
    }

    & img {
      width: 80px;
      height: 80px;
    }
  }

  .experience-bottom {
    margin-top: 5px;
    margin-right: 5px;
    font-family: 'Open Sans', sans-serif;
  }

  maxPlayers {
    font-family: 'Open Sans', sans-serif;
  }
.experience-container {
  padding: 10px;
  border-color: gray;
  background-color: #F7F8F8;
  border-width: 1px;
  border-style: solid;
  opacity: 0.7;
}

.experience-tiers {
  position: absolute;
  transform: translate(296%, 30px);
  width: 100px;
  align-items: start;
}

.experience-tiers-grid {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  align-items: start;
}

.experience-tiers-row {
  padding-top: 10px;
  padding-bottom: 10px;
  border: darkgray solid 1px;
  text-align: center;
  border-radius: 5px;
  margin-top: 12.3px;
  border-radius: 0;
  margin-left: -1px;
  align-items: start;
}

.experience-tiers-price {
  background-color: #F7F8F8;
  color: #1F1E1E;
  font-size: small;
  align-items: start;

  &.experience-tiers-price.selected {
    background-color: gray;
  }
}

.experience-tiers-length {
  background-color: #F7F8F8;
  color: #1F1E1E;
  font-size: small;
  transform: translate(0px, 5px);
}
}

.dark-mode {
  .container-width {
    @apply --contentContainer;
    max-width: 120rem;
    padding: 0 var(--spacingM);
  }

  .experiences {
    display: grid;
    z-index: 5;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-top: var(--spacingMd);

    @media (--tablet) {
      gap: 80px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .experience {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .experience-header {
      flex-direction: column;

      @media (--tablet) {
        flex-direction: row;
      }
    }

    .description,
    .experience-bottom,
    .member {
      color: #F7F8F8;
        font-size: 1em;
        font-family: 'Open Sans', sans-serif;
    }

    .experience-bottom {
      margin-top: 5px;
      margin-right: 5px;
    }

    .member {
      fill: #F7F8F8;
    }

    .image {
      border: 1px solid #879AB2;
    display: flex;

      &:hover {
        border-color: var(--colorSBRedFlat);

        &:after {
          opacity: 0.5;
        }
      }
    }

    .image img {
      aspect-ratio: 280 / 180;
    }

    &.active .image {
      border-color: var(--colorSBRedFlat);

      &:after {
        opacity: 1;
      }
    }
  }

  .experience-container {
    padding: 10px;
    border-color: gray;
    background-color: #1F1E1E;
    border-width: 1px;
    border-style: solid;
    opacity: 0.7;
  }

  .experience-tiers {
    position: absolute;
    transform: translate(296%, 30px);
    width: 100px;
    align-items: start;
  }

.experience-tiers-grid {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.experience-arrow {
  position: absolute;
  pointer-events: none;
  transition: opacity 0.3s ease;
  cursor: pointer;
  filter: opacity(0.7);
  filter: grayscale(70%);

  &.left {
    left: 12em;
    bottom: 70%;
  }

  &.right {
    left: 165em;
    bottom: 70%;
  }

  &.enabled {
    pointer-events: auto;
    filter: opacity(0.6);

    &:hover {
      filter: opacity(1);
    }
  }
  &.disabled {
    display:none;
  }

  & img {
    width: 80px;
    height: 80px;
  }
}

.experience-tiers-row {
  padding-top: 10px;
  padding-bottom: 10px;
  border: darkgray solid 1px;
  text-align: center;
  border-radius: 5px;
  margin-top: 12.3px;
  border-radius: 0;
  margin-left: -1px;
}

.experience-tiers-price {
  background-color: #F7F8F8;
  color: #1F1E1E;
  font-size: small;

  &.experience-tiers-price.selected {
    background-color: gray;
  }
}

.experience-tiers-length {
  background-color: #F7F8F8;
  color: #1F1E1E;
  font-size: small;
  transform: translate(0px, 5px);
}

  .experience-tiers-price,
  .experience-tiers-length {
    background-color: #1F1E1E;
    color: #F7F8F8;
    font-size: small;
  }

  .title {
    @apply --f5;
    font-size: 14px;
    color: #F7F8F8;

    @media (--tablet) {
      font-size: 18px;
    }
  }

  .section-title {
    @apply --f2;
    font-size: 26px;
    z-index: 5;
    font-weight: 800;
    line-height: 28px;
    margin-top: 0;
    color: #F7F8F8;
    margin-bottom: var(--spacingSm);

    @media (--tablet) {
      font-size: 35px;
      margin-bottom: var(--spacingMd);
    }
  }
  .member {
    fill: #F7F8F8;
    width: 16px;
    height: 18px;
    margin-right: 4px;
    margin-bottom: -5px;

    @media(--tablet) {
      margin-right: 0;
      margin-left: 4px;
    }
  }
}

</style>
