import rest from '@/core/restAPI';
import { setWaiverBooking, setWaiverLocation, setWaiverHost, setWaiverStationTimes, setWaiverContent, setWaiverMyWaiver, setWaiverSignedWaivers, removeWaiverSignedWaiver } from './mutations';

export const getWaiverInfoHost = async ({ state }, waiverReferenceHost) => {
  const result = await rest.get(`public/waivers/${waiverReferenceHost}`).json();
  setWaiverBooking(state, result.booking);
  setWaiverLocation(state, result.location);
  setWaiverHost(state, result.host);
  setWaiverStationTimes(state, result.stationTimes);
  setWaiverSignedWaivers(state, result.signedWaivers);
};

export const setNumSurprisedGuests = async ({ state }, { waiverReferenceHost, numSurprisedGuests }) => {
  const booking = await rest.get(`public/waivers/set-num-surprised-guests/${waiverReferenceHost}/${numSurprisedGuests}`).json();
  setWaiverBooking(state, booking);
};

export const setNumMinors = async ({ state }, { waiverReferenceHost, numMinors }) => {
  const booking = await rest.get(`public/waivers/set-num-minors/${waiverReferenceHost}/${numMinors}`).json();
  setWaiverBooking(state, booking);
};

export const removeSignedWaiver = async ({ state }, { waiverReferenceHost, waiverId }) => {
  await rest.get(`public/waivers/remove-waiver/${waiverReferenceHost}/${waiverId}`);
  removeWaiverSignedWaiver(state, waiverId);
};

export const getWaiverInfoGuest = async ({ state }, { waiverReferenceGuest, language }) => {
  const result = await rest.get(`public/waivers/${waiverReferenceGuest}/${language}`).json();
  setWaiverBooking(state, result.booking);
  setWaiverLocation(state, result.location);
  setWaiverHost(state, result.host);
  setWaiverStationTimes(state, result.stationTimes);
  setWaiverContent(state, result.waiverContent);
};

export const getPreviouslySignedWaiver = async ({ state }, { waiverReferenceGuest, email }) => {
  const result = await rest.get(`public/waivers/prev-signed/${waiverReferenceGuest}/${email}`).json();
  setWaiverMyWaiver(state, result.myWaiver);
};

export const signWaiver = async ({ state }, { waiverReferenceGuest, language }) => {
  const payload = {
    waiverReferenceGuest: waiverReferenceGuest,
    waiver: state.myWaiver,
  };

  return rest.post(`public/waivers/sign/${language}`, { json: payload }).json();
};
